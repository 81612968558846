
import useFetchTechs from '../methods/useFetchTechs';
import React, { useEffect, useState } from 'react';
import '../css/Data.css';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Typography, Box, FormControlLabel, Switch, TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function Technicians() {
    const { techs, error } = useFetchTechs();
    const [searchText, setSearchText] = useState('');

    const columns = [
        { field: 'name', headerName: 'Name', sortable: true }
    ];

    const rows = Object.values(techs).flat().map((tech, index) => {
        return {
            id: index,
            ...tech
        };
    });

    return (
        <div style = {{ height: 400, width: '80%', paddingRight: 50, marginLeft: 150, marginRight: 150 }}>
            <Box>
            <TextField
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position= "end">
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                    )
            }}
                />
            </Box>
            <DataGrid rows={rows} columns={columns} slows={{ toolbar: GridToolbar}} pageSize={5} />
        </div>
    );
}

export default Technicians;